@use 'reset.scss';
@use 'vars.scss';
@use 'globals.scss';

html {
    /* scroll to anchor smoothly to make it more intuitive that previous menu is available above */
    scroll-behavior: smooth;
}

body {
    font-size: 1em;
}

#root-container {
    flex-direction: column;
    width: 100%;
}

.section-header {
    font-size: 2.5em;

}

/* only on mobile, allow changing background  of the body */
.mobile-bgcolor-a {
    background-color: vars.$color-a;
}

.mobile-bgcolor-b {
    background-color: vars.$color-b;
}

.section-header.back {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}
.section-header.back::before {
        content: '\2190';
        font-size: 0.6em;
        // margin-bottom: 0.5em;
        margin-top: 0.7em;
        margin-left: 1em;
}

.left-section {
    width: 100%;
}

.right-section {
    hr {
        /* account for right-section padding, move hr back to left edge of the screen */
        margin-left: calc(-1 * vars.$container-border-margin);
    }
}

.content-container {
    margin-top: 2.5em; /* account for height difference in lowercase fonts, make margins uniform */

    h2 {
        font-size: 1.3em;

    }

    h3 {
        font-size: 1em;
    }

    p {
        padding-bottom: vars.$container-border-margin;
    }
}

/* no dates on narrow mobile screens */
.li-with-date {
    li>.li-with-date-item {
        display: inline;
        .li-spacing {
            display: none;
        }

        .li-date {
            display: none;
        }
    }
}


hr {
    width: 100vw;
}
